import { PdfToCsvConverter } from 'components/src/components/Elements/PdfToCsvConverter/PdfToCsvConverter';
import { SupportedBanks } from 'components/src/components/Elements/UploadFileToConvert/UploadFileToConvert';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import * as React from 'react';
import { AuthWrapper } from '../auth/AuthWrapper';
import { Header } from '../components/Header';
import { getCompanyId } from '../storage/sessionStorageHelpers';

const IndexPage = (props) => {
  const { id } = queryString.parse(props.location.search);


  return (
    <AuthWrapper>
      <Header>
        <span className="number">2.</span>
        Upuść plik
      </Header>
      <PdfToCsvConverter
        bankId={id ? id as SupportedBanks : undefined}
        getCompanyId={getCompanyId}
        onUploadSuccess={({ jobId , fileName}) => {
          navigate(`/poczekaj-aż-skonwertujemy-Twój-plik?jobId=${jobId}&fileName=${fileName}`);
        }}
      />
    </AuthWrapper>
  );
};

export default IndexPage;
