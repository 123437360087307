import { SupportedBanks } from 'components/src/components/Elements/UploadFileToConvert/UploadFileToConvert';
import * as React from 'react';
import { apiRequest } from './apiRequest';
import { API_URL } from './request';

const CONVERT_URL = `${API_URL}/upload`;

type OnUploadSuccessParams = {
  jobId: string;
  fileName: string;
};

type FileUploadHookParams = {
  onUploadSuccess(params: OnUploadSuccessParams);
  onUploadFailure({ error, fileName }: { error: any, fileName: string });
};

export const convertRequestHook = ({ onUploadSuccess, onUploadFailure }: FileUploadHookParams) => {
  const [isSending, setIsSending] = React.useState(false);
  const handleSend = React.useCallback(async (file: File, bankName: SupportedBanks = 'auto', companyId?: string) => {
    const fileName = file.name;
    if (isSending) {
      return;
    }

    setIsSending(true);

    const formData = new FormData();
    formData.append('fileField', file);

    if (companyId) {
      formData.append('companyId', companyId);
    }

    try {
      const url = bankName ? `${CONVERT_URL}/${bankName}` : `${CONVERT_URL}/`;

      const response = await apiRequest(url, {
        body: formData,
        method: 'POST',
      });

      if (response) {
        const { jobId } = await response.json();

        onUploadSuccess({ jobId, fileName });
      }
    } catch (error) {
      onUploadFailure({ error, fileName });
    }
    setIsSending(false);
  }, [isSending]);

  return { isSending, handleSend };
};
